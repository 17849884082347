import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { ReactElement, useMemo } from 'react';

import Status from '~/ui/components/common/Status';

import {
  DictionaryCustomFieldType,
  ICustomDictionaryItem,
  ICustomDictionaryType,
} from '~/services/api/dictionaries/types';

import archiveIcon from '~/ui/assets/images/archiveIcon.svg';
import editGrey from '~/ui/assets/images/editGrey.svg';

import styles from '../../../Dictionaries.module.scss';
import { Checkbox } from '@material-ui/core';

interface IProps {
  dictionaryItem: any;
  dictionaryType: ICustomDictionaryType;
  hasCustomFields: boolean;
  setDictionaryItem: (item: ICustomDictionaryItem | null) => void;
  onEditDictionaryItem: (item: ICustomDictionaryItem) => void;
}

const TableBodyItem = ({
  dictionaryItem,
  hasCustomFields,
  dictionaryType,
  setDictionaryItem,
  onEditDictionaryItem,
}: IProps): ReactElement => {
  const bodyCustomFields = useMemo(() => {
    if (hasCustomFields)
      return dictionaryType.customFields.map(({ name, type }) => {
        let customFieldValue = dictionaryItem[name as keyof typeof dictionaryItem];
        if (type === DictionaryCustomFieldType.CHECKBOX) {
          customFieldValue = (
            <Checkbox checked={Boolean(customFieldValue)} color="primary" disabled />
          );
        }
        return <TableCell key={name}>{customFieldValue}</TableCell>;
      });
    return null;
  }, [dictionaryItem, dictionaryType.customFields, hasCustomFields]);

  return (
    <TableRow key={dictionaryItem.id} className={styles.tableRowHeight}>
      <TableCell>{dictionaryItem.value}</TableCell>
      {hasCustomFields && bodyCustomFields}
      <TableCell>
        <Status isArchived={dictionaryItem.isArchived} />
      </TableCell>
      <TableCell>
        {dictionaryItem.isArchived ? (
          <div
            role="presentation"
            className={styles.actionButton}
            onClick={() => {
              setDictionaryItem(dictionaryItem);
            }}
          >
            Restore
          </div>
        ) : (
          <>
            <IconButton
              onClick={() => {
                onEditDictionaryItem(dictionaryItem);
              }}
            >
              <img src={editGrey} alt="edit" />
            </IconButton>
            <IconButton
              onClick={() => {
                setDictionaryItem(dictionaryItem);
              }}
            >
              <img src={archiveIcon} alt="archive" />
            </IconButton>
          </>
        )}
      </TableCell>
    </TableRow>
  );
};

export default TableBodyItem;
