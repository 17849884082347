import { ReactElement, lazy } from 'react';
import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom';

import Loader from './components/common/Loader';
import MyActTeam from './pages/ActTeam/ActTeam/MyActTeam';
import ActTeamManagementLocalAdmin from './pages/ActTeam/LocalAdmin/ActTeamManagement';
import AddClientAllocation from './pages/ActTeam/LocalAdmin/AddClientAllocation';
import AddClientAllocationGU from './pages/ActTeam/GlobalUser/AddClientAllocation';
import AddTeamMember from './pages/ActTeam/LocalAdmin/AddTeamMember';
import DuplicateClientAllocation from './pages/ActTeam/LocalAdmin/DuplicateClientAllocation';
import EditClientAllocation from './pages/ActTeam/reusable/ClientAllocationForm/EditClientAllocation';
import EditTeamMember from './pages/ActTeam/LocalAdmin/EditTeamMember';
import ViewActTeamLocalAdmin from './pages/ActTeam/LocalAdmin/ViewActTeam';
import ViewClientAllocation from './pages/ActTeam/LocalAdmin/ViewClientAllocation';
import ActTeamManagement from './pages/ActTeam/SuperAdmin/ActTeamManagement';
import AddActTeam from './pages/ActTeam/SuperAdmin/AddActTeam';
import ViewTeamMemberProfile from './pages/ActTeam/reusable/TeamMemberProfile';
import ChangePassword from './pages/ChangePassword';
import MyClients from './pages/Clients/ActTeam/MyClients';
import AddClient from './pages/Clients/LocalAdmin/AddClient';
import ClientProfile from './pages/Clients/LocalAdmin/ClientProfile';
import ClientsManagement from './pages/Clients/LocalAdmin/ClientsManagement';
import EditClient from './pages/Clients/LocalAdmin/EditClient';
import MyClinic from './pages/Clinics/MyClinic';
import Resources from './pages/Resources';
import ViewClinic from './pages/Clinics/ViewClinic';
import ClinicCreateActTeam from './pages/Clinics/ViewClinic/ActTeam/CreateActTeam';
import ClinicEditActTeam from './pages/Clinics/ViewClinic/ActTeam/EditActTeam';
import ClinicActTeam from './pages/Clinics/ViewClinic/ActTeam/ViewActTeam';
import ClinicAddLocalAdmin from './pages/Clinics/ViewClinic/LocalAdmin/AddLocalAdmin';
import ClinicEditLocalAdmin from './pages/Clinics/ViewClinic/LocalAdmin/EditLocalAdmin';
import ViewLocalAdmin from './pages/Clinics/ViewClinic/LocalAdmin/ViewLocalAdmin';
import AddProgramAssistant from './pages/Clinics/ViewClinic/ProgramAssistant/AddProgramAssistant';
import EditProgramAssistant from './pages/Clinics/ViewClinic/ProgramAssistant/EditProgramAssistant';
import ViewProgramAssistant from './pages/Clinics/ViewClinic/ProgramAssistant/ViewProgramAssistant';
import ForgotPassword from './pages/ForgotPassword';
import Home from './pages/Home';
import MedicationLogistics from './pages/MedicationLogistics';
import NotFound from './pages/NotFoundPage';
import Notifications from './pages/Notifications';
import Profile from './pages/Profile';
import EditProfile from './pages/Profile/EditProfile';
import Reports from './pages/Reports';
import SignIn from './pages/SignIn';
import SignUp from './pages/SignUp';
import Visits from './pages/Visits';
import AddVisit from './pages/Visits/components/AddVisit';
import PreviewVisit from './pages/Visits/components/PreviewVisit';
import PrivateRoute from './routes/PrivateRoute';
import PublicRoute from './routes/PublicRoute';

import { useStoreState } from '~/store/hooks';
import useRole from '../store/user/hooks/useRole';

import {
  ACT_TEAM_MANAGEMENT,
  ADD_ACT_TEAM,
  ADD_CLIENT,
  ADD_CLIENT_ALLOCATION,
  ADD_CLINIC,
  ADD_SUPER_ADMIN,
  ADD_TEAM_MEMBER,
  ADD_VISIT,
  CHANGE_PASSWORD,
  CLIENT,
  CLINIC_ADD_LOCAL_ADMIN,
  CLINIC_ADD_PROGRAM_ASSISTANT,
  CLINIC_CREATE_ACT_TEAM,
  CLINIC_EDIT_ACT_TEAM,
  CLINIC_EDIT_LOCAL_ADMIN,
  CLINIC_EDIT_PROGRAM_ASSISTANT,
  CLINIC_MANAGEMENT,
  CLINIC_VIEW_ACT_TEAM,
  CLINIC_VIEW_LOCAL_ADMIN,
  CLINIC_VIEW_PROGRAM_ASSISTANT,
  COORDINATE,
  DICTIONARIES,
  DUPLICATE_CLIENT_ALLOCATION,
  EDIT_CLIENT,
  EDIT_CLIENT_ALLOCATION,
  EDIT_CLINIC,
  EDIT_PROFILE,
  EDIT_SUPER_ADMIN,
  EDIT_TEAM_MEMBER,
  EDIT_VISIT,
  FORGOT_PASSWORD,
  MEDICATION_LOGISTICS,
  MY_ACT_TEAM,
  MY_CLIENTS,
  MY_CLINIC,
  NOTIFICATIONS,
  PREVIEW_VISIT,
  PROFILE,
  REPORTS,
  ORGANIZATION_MANAGEMENT,
  SELECT_ROLE,
  SIGN_IN,
  SIGN_UP,
  SUPER_ADMIN,
  VIEW_ACT_TEAM,
  VIEW_ACT_TEAM_MEMBER,
  VIEW_CLIENT,
  VIEW_CLIENT_ALLOCATION,
  VIEW_CLINIC,
  VISITS,
  RESOURCE,
  RELEASE_MANAGEMENT,
  ADD_RELEASE,
  EDIT_RELEASE,
  ADD_SYSTEM_ALERT,
  SYSTEM_ALERTS_MANAGEMENT,
  VIEW_MY_CLIENT_ALLOCATION,
  EDIT_MY_CLIENT_ALLOCATION,
  ADD_MY_CLIENT_ALLOCATION,
  DUPLICATE_MY_CLIENT_ALLOCATION,
  AI_MANAGEMENT,
  NETWORK,
} from './constants/paths';

import Role from './pages/Role';
import SuspenseLayout from './components/common/SuspenseLayout';
import MyClientAllocations from './pages/ActTeam/GlobalUser/MyClientAllocations/MyClientAllocations';

const Coordinate = lazy(() => import('./pages/Coordinate'));
const SystemDictionaries = lazy(() => import('./pages/Dictionaries/SystemDictionaries'));

// lazy load product owner and super admin pages
const OrganizationManagement = lazy(() => import('./pages/Organizations/OrganizationManagement'));
const SuperAdminsManagement = lazy(() => import('./pages/SuperAdmins/SuperAdminsManagement'));
const AddSuperAdmin = lazy(() => import('./pages/SuperAdmins/AddSuperAdmin'));
const EditSuperAdmin = lazy(() => import('./pages/SuperAdmins/EditSuperAdmin'));
const SystemAlertsManagement = lazy(() => import('./pages/SystemAlerts/SystemAlertsManagement'));
const AddClinic = lazy(() => import('./pages/Clinics/AddClinic'));
const ClinicManagement = lazy(() => import('./pages/Clinics/ClinicManagement'));
const AiManagement = lazy(() => import('./pages/AiManagement'));
const Network = lazy(() => import('./pages/Network'));
const ReleaseManagement = lazy(() => import('./pages/Releases/ReleaseManagement'));
const AddRelease = lazy(() => import('./pages/Releases/AddRelease'));
const EditRelease = lazy(() => import('./pages/Releases/EditRelease'));
const AddSystemAlert = lazy(() => import('./pages/SystemAlerts/AddSystemAlert'));
const EditClinic = lazy(() => import('./pages/Clinics/EditClinic'));

const AppRoutes = (): ReactElement => {
  const { authChecked, current } = useStoreState(state => state.user);

  const {
    isSuperAdmin,
    isLocalAdmin,
    isProductOwner,
    isProgramAssistant,
    isActTeam,
    isGlobalUser,
  } = useRole();

  if (!current?.roleId && !authChecked) return <Loader />;

  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route path="/" element={<Home />} />
        <Route
          path={SIGN_UP}
          key={SIGN_UP}
          element={
            <PublicRoute>
              <SignUp />
            </PublicRoute>
          }
        />
        <Route
          path={SELECT_ROLE}
          key={SELECT_ROLE}
          element={
            <PublicRoute>
              <Role />
            </PublicRoute>
          }
        />
        <Route
          path={SIGN_IN}
          key={SIGN_IN}
          element={
            <PublicRoute>
              <SignIn />
            </PublicRoute>
          }
        />
        <Route
          path={FORGOT_PASSWORD}
          key={FORGOT_PASSWORD}
          element={
            <PublicRoute>
              <ForgotPassword />
            </PublicRoute>
          }
        />
        <Route
          path={CHANGE_PASSWORD}
          key={CHANGE_PASSWORD}
          element={
            <PublicRoute>
              <ChangePassword />
            </PublicRoute>
          }
        />
        <Route
          path={PROFILE}
          key={PROFILE}
          element={
            <PrivateRoute>
              <Profile />
            </PrivateRoute>
          }
        />
        <Route
          path={EDIT_PROFILE}
          key={EDIT_PROFILE}
          element={
            <PrivateRoute>
              <EditProfile />
            </PrivateRoute>
          }
        />
        {isSuperAdmin || isProductOwner ? (
          [
            <Route element={<SuspenseLayout />} key={CLINIC_MANAGEMENT}>
              <Route
                path={CLINIC_MANAGEMENT}
                key={CLINIC_MANAGEMENT}
                element={
                  <PrivateRoute>
                    <ClinicManagement />
                  </PrivateRoute>
                }
              />
              ,
              <Route
                path={RELEASE_MANAGEMENT}
                key={RELEASE_MANAGEMENT}
                element={
                  <PrivateRoute>
                    <ReleaseManagement />
                  </PrivateRoute>
                }
              />
              ,
              <Route
                path={ADD_RELEASE}
                key={ADD_RELEASE}
                element={
                  <PrivateRoute>
                    <AddRelease />
                  </PrivateRoute>
                }
              />
              ,
              <Route
                path={EDIT_RELEASE}
                key={EDIT_RELEASE}
                element={
                  <PrivateRoute>
                    <EditRelease />
                  </PrivateRoute>
                }
              />
              ,
              <Route
                path={SYSTEM_ALERTS_MANAGEMENT}
                key={SYSTEM_ALERTS_MANAGEMENT}
                element={
                  <PrivateRoute>
                    <SystemAlertsManagement />
                  </PrivateRoute>
                }
              />
              ,
              <Route
                path={ADD_SYSTEM_ALERT}
                key={ADD_SYSTEM_ALERT}
                element={
                  <PrivateRoute>
                    <AddSystemAlert />
                  </PrivateRoute>
                }
              />
              ,
            </Route>,
          ]
        ) : (
          <Route
            path={MY_CLINIC}
            key={MY_CLINIC}
            element={
              <PrivateRoute>
                <MyClinic />
              </PrivateRoute>
            }
          />
        )}
        {isSuperAdmin && [
          <Route element={<SuspenseLayout />} key={SYSTEM_ALERTS_MANAGEMENT}>
            <Route
              path={SYSTEM_ALERTS_MANAGEMENT}
              key={SYSTEM_ALERTS_MANAGEMENT}
              element={
                <PrivateRoute>
                  <SystemAlertsManagement />
                </PrivateRoute>
              }
            />
            ,
            <Route
              path={ADD_SYSTEM_ALERT}
              key={ADD_SYSTEM_ALERT}
              element={
                <PrivateRoute>
                  <AddSystemAlert />
                </PrivateRoute>
              }
            />
            ,
          </Route>,
        ]}
        <Route element={<SuspenseLayout />} key={ADD_CLINIC}>
          <Route
            path={ADD_CLINIC}
            key={ADD_CLINIC}
            element={
              <PrivateRoute>
                <AddClinic />
              </PrivateRoute>
            }
          />
          <Route
            path={EDIT_CLINIC}
            key={EDIT_CLINIC}
            element={
              <PrivateRoute>
                <EditClinic />
              </PrivateRoute>
            }
          />
          <Route
            path={VIEW_CLINIC}
            key={VIEW_CLINIC}
            element={
              <PrivateRoute>
                <ViewClinic />
              </PrivateRoute>
            }
          />
        </Route>
        <Route
          path={CLINIC_VIEW_ACT_TEAM}
          key={CLINIC_VIEW_ACT_TEAM}
          element={
            <PrivateRoute>
              <ClinicActTeam />
            </PrivateRoute>
          }
        />
        <Route
          path={CLINIC_EDIT_ACT_TEAM}
          key={CLINIC_EDIT_ACT_TEAM}
          element={
            <PrivateRoute>
              <ClinicEditActTeam />
            </PrivateRoute>
          }
        />
        <Route
          path={CLINIC_ADD_LOCAL_ADMIN}
          key={CLINIC_ADD_LOCAL_ADMIN}
          element={
            <PrivateRoute>
              <ClinicAddLocalAdmin />
            </PrivateRoute>
          }
        />
        <Route
          path={CLINIC_EDIT_LOCAL_ADMIN}
          key={CLINIC_EDIT_LOCAL_ADMIN}
          element={
            <PrivateRoute>
              <ClinicEditLocalAdmin />
            </PrivateRoute>
          }
        />
        <Route
          path={CLINIC_VIEW_LOCAL_ADMIN}
          key={CLINIC_VIEW_LOCAL_ADMIN}
          element={
            <PrivateRoute>
              <ViewLocalAdmin />
            </PrivateRoute>
          }
        />
        <Route
          path={CLINIC_ADD_PROGRAM_ASSISTANT}
          key={CLINIC_ADD_PROGRAM_ASSISTANT}
          element={
            <PrivateRoute>
              <AddProgramAssistant />
            </PrivateRoute>
          }
        />
        <Route
          path={CLINIC_EDIT_PROGRAM_ASSISTANT}
          key={CLINIC_EDIT_PROGRAM_ASSISTANT}
          element={
            <PrivateRoute>
              <EditProgramAssistant />
            </PrivateRoute>
          }
        />
        <Route
          path={CLINIC_VIEW_PROGRAM_ASSISTANT}
          key={CLINIC_VIEW_PROGRAM_ASSISTANT}
          element={
            <PrivateRoute>
              <ViewProgramAssistant />
            </PrivateRoute>
          }
        />
        <Route
          path={CLINIC_CREATE_ACT_TEAM}
          key={CLINIC_CREATE_ACT_TEAM}
          element={
            <PrivateRoute>
              <ClinicCreateActTeam />
            </PrivateRoute>
          }
        />
        {(isSuperAdmin || isProductOwner) && (
          <Route
            path={ACT_TEAM_MANAGEMENT}
            key={ACT_TEAM_MANAGEMENT}
            element={
              <PrivateRoute>
                <ActTeamManagement />
              </PrivateRoute>
            }
          />
        )}
        {isProductOwner && [
          <Route element={<SuspenseLayout />} key={SUPER_ADMIN}>
            <Route
              path={AI_MANAGEMENT}
              key={AI_MANAGEMENT}
              element={
                <PrivateRoute>
                  <AiManagement />
                </PrivateRoute>
              }
            />
            ,
            <Route
              path={SUPER_ADMIN}
              key={SUPER_ADMIN}
              element={
                <PrivateRoute>
                  <SuperAdminsManagement />
                </PrivateRoute>
              }
            />
            ,
            <Route
              path={ADD_SUPER_ADMIN}
              key={ADD_SUPER_ADMIN}
              element={
                <PrivateRoute>
                  <AddSuperAdmin />
                </PrivateRoute>
              }
            />
            ,
            <Route
              path={EDIT_SUPER_ADMIN}
              key={EDIT_SUPER_ADMIN}
              element={
                <PrivateRoute>
                  <EditSuperAdmin />
                </PrivateRoute>
              }
            />
            ,
            <Route
              path={ORGANIZATION_MANAGEMENT}
              key={ORGANIZATION_MANAGEMENT}
              element={
                <PrivateRoute>
                  <OrganizationManagement />
                </PrivateRoute>
              }
            />
            ,
          </Route>,
        ]}
        {isGlobalUser && [
          <Route
            path={VIEW_MY_CLIENT_ALLOCATION}
            key={VIEW_MY_CLIENT_ALLOCATION}
            element={
              <PrivateRoute>
                <MyClientAllocations />
              </PrivateRoute>
            }
          />,
          <Route
            path={EDIT_MY_CLIENT_ALLOCATION}
            key={EDIT_MY_CLIENT_ALLOCATION}
            element={
              <PrivateRoute>
                <EditClientAllocation />
              </PrivateRoute>
            }
          />,
          <Route
            path={ADD_MY_CLIENT_ALLOCATION}
            key={ADD_MY_CLIENT_ALLOCATION}
            element={
              <PrivateRoute>
                <AddClientAllocationGU />
              </PrivateRoute>
            }
          />,
          <Route
            path={DUPLICATE_MY_CLIENT_ALLOCATION}
            key={DUPLICATE_MY_CLIENT_ALLOCATION}
            element={
              <PrivateRoute>
                <DuplicateClientAllocation />
              </PrivateRoute>
            }
          />,
          <Route
            path={CLIENT}
            key={CLIENT}
            element={
              <PrivateRoute>
                <ClientsManagement />
              </PrivateRoute>
            }
          />,
          <Route
            path={VIEW_CLIENT}
            key={VIEW_CLIENT}
            element={
              <PrivateRoute>
                <ClientProfile />
              </PrivateRoute>
            }
          />,
        ]}
        {(isLocalAdmin || isProgramAssistant) && [
          <Route
            path={ACT_TEAM_MANAGEMENT}
            key={ACT_TEAM_MANAGEMENT}
            element={
              <PrivateRoute>
                <ActTeamManagementLocalAdmin />
              </PrivateRoute>
            }
          />,
          <Route
            path={VIEW_ACT_TEAM}
            key={VIEW_ACT_TEAM}
            element={
              <PrivateRoute>
                <ViewActTeamLocalAdmin />
              </PrivateRoute>
            }
          />,
          <Route
            path={EDIT_TEAM_MEMBER}
            key={EDIT_TEAM_MEMBER}
            element={
              <PrivateRoute>
                <EditTeamMember />
              </PrivateRoute>
            }
          />,
          <Route
            path={ADD_TEAM_MEMBER}
            key={ADD_TEAM_MEMBER}
            element={
              <PrivateRoute>
                <AddTeamMember />
              </PrivateRoute>
            }
          />,
          <Route
            path={CLIENT}
            key={CLIENT}
            element={
              <PrivateRoute>
                <ClientsManagement />
              </PrivateRoute>
            }
          />,
          <Route
            path={ADD_CLIENT}
            key={ADD_CLIENT}
            element={
              <PrivateRoute>
                <AddClient />
              </PrivateRoute>
            }
          />,
          <Route
            path={EDIT_CLIENT}
            key={EDIT_CLIENT}
            element={
              <PrivateRoute>
                <EditClient />
              </PrivateRoute>
            }
          />,
          <Route
            path={VIEW_CLIENT}
            key={VIEW_CLIENT}
            element={
              <PrivateRoute>
                <ClientProfile />
              </PrivateRoute>
            }
          />,
          <Route
            path={VIEW_ACT_TEAM_MEMBER}
            key={VIEW_ACT_TEAM_MEMBER}
            element={
              <PrivateRoute>
                <ViewTeamMemberProfile />
              </PrivateRoute>
            }
          />,
        ]}
        {(isLocalAdmin || isProgramAssistant || isActTeam || isGlobalUser) && [
          <Route
            path={NOTIFICATIONS}
            key={NOTIFICATIONS}
            element={
              <PrivateRoute>
                <Notifications />
              </PrivateRoute>
            }
          />,
          <Route
            path={ADD_CLIENT_ALLOCATION}
            key={ADD_CLIENT_ALLOCATION}
            element={
              <PrivateRoute>
                <AddClientAllocation />
              </PrivateRoute>
            }
          />,
          <Route
            path={VIEW_CLIENT_ALLOCATION}
            key={VIEW_CLIENT_ALLOCATION}
            element={
              <PrivateRoute>
                <ViewClientAllocation />
              </PrivateRoute>
            }
          />,
          <Route
            path={EDIT_CLIENT_ALLOCATION}
            key={EDIT_CLIENT_ALLOCATION}
            element={
              <PrivateRoute>
                <EditClientAllocation />
              </PrivateRoute>
            }
          />,
          <Route
            path={DUPLICATE_CLIENT_ALLOCATION}
            key={DUPLICATE_CLIENT_ALLOCATION}
            element={
              <PrivateRoute>
                <DuplicateClientAllocation />
              </PrivateRoute>
            }
          />,
          <Route
            path={REPORTS}
            key={REPORTS}
            element={
              <PrivateRoute>
                <Reports />
              </PrivateRoute>
            }
          />,
        ]}
        {(isActTeam || isGlobalUser) && [
          <Route
            path={MY_ACT_TEAM}
            key={MY_ACT_TEAM}
            element={
              <PrivateRoute>
                <MyActTeam />
              </PrivateRoute>
            }
          />,
          <Route
            path={MY_CLIENTS}
            key={MY_CLIENTS}
            element={
              <PrivateRoute>
                <MyClients />
              </PrivateRoute>
            }
          />,
          <Route
            path={VIEW_CLIENT}
            key={VIEW_CLIENT}
            element={
              <PrivateRoute>
                <ClientProfile />
              </PrivateRoute>
            }
          />,
          <Route
            path={VIEW_ACT_TEAM_MEMBER}
            key={VIEW_ACT_TEAM_MEMBER}
            element={
              <PrivateRoute>
                <ViewTeamMemberProfile />
              </PrivateRoute>
            }
          />,
        ]}
        {(isLocalAdmin || isActTeam || isProgramAssistant || isGlobalUser) && (
          <Route element={<SuspenseLayout />} key={SUPER_ADMIN}>
            [
            <Route
              path={NETWORK}
              key={NETWORK}
              element={
                <PrivateRoute>
                  <Network />
                </PrivateRoute>
              }
            />
            ,
            <Route
              path={MEDICATION_LOGISTICS}
              key={MEDICATION_LOGISTICS}
              element={
                <PrivateRoute>
                  <MedicationLogistics />
                </PrivateRoute>
              }
            />
            ,
            <Route
              path={VISITS}
              key={VISITS}
              element={
                <PrivateRoute>
                  <Visits />
                </PrivateRoute>
              }
            />
            ,
            <Route
              path={ADD_VISIT}
              key={ADD_VISIT}
              element={
                <PrivateRoute>
                  <AddVisit />
                </PrivateRoute>
              }
            />
            ,
            <Route
              path={EDIT_VISIT}
              key={EDIT_VISIT}
              element={
                <PrivateRoute>
                  <AddVisit />
                </PrivateRoute>
              }
            />
            ,
            <Route
              path={PREVIEW_VISIT}
              key={PREVIEW_VISIT}
              element={
                <PrivateRoute>
                  <PreviewVisit />
                </PrivateRoute>
              }
            />
            ]
          </Route>
        )}
        {(isSuperAdmin || isLocalAdmin || isActTeam || isProgramAssistant || isGlobalUser) && [
          <Route
            path={RESOURCE}
            key={RESOURCE}
            element={
              <PrivateRoute>
                <Resources />
              </PrivateRoute>
            }
          />,
        ]}
        {(isActTeam || isGlobalUser) && [
          <Route element={<SuspenseLayout />} key={COORDINATE}>
            <Route
              path={COORDINATE}
              key={COORDINATE}
              element={
                <PrivateRoute>
                  <Coordinate />
                </PrivateRoute>
              }
            />
          </Route>,
        ]}
        <Route
          path={ADD_ACT_TEAM}
          key={ADD_ACT_TEAM}
          element={
            <PrivateRoute>
              <AddActTeam />
            </PrivateRoute>
          }
        />
        ,
        <Route element={<SuspenseLayout />} key={DICTIONARIES}>
          <Route
            path={DICTIONARIES}
            key={DICTIONARIES}
            element={
              <PrivateRoute>
                <SystemDictionaries />
              </PrivateRoute>
            }
          />
        </Route>
        ,
        <Route path="*" element={<NotFound />} />
      </>,
    ),
  );

  return <RouterProvider router={router} fallbackElement={<Loader />} />;
};

export default AppRoutes;
