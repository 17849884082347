import { delay } from 'lodash';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStoreActions } from '~/store/hooks';
import { SIGN_IN } from '~/ui/constants/paths';

const LOGOUT_INACTIVITY_DELAY = 1000 * 60 * 60;
const NAVIGATION_DELAY = 3000;

interface IUseInactivityLogoutProps {
  timeout?: number;
  skip?: boolean;
  onLogout: () => void;
}

const useInactivityLogout = ({
  timeout = LOGOUT_INACTIVITY_DELAY,
  skip = false,
  onLogout,
}: IUseInactivityLogoutProps): void => {
  // Default timeout is 15 minutes
  const [lastActivity, setLastActivity] = useState(Date.now());

  const navigate = useNavigate();
  const { showError, hide } = useStoreActions(actions => actions.snackbar);

  useEffect(() => {
    if (skip) return undefined;
    // Function to reset the inactivity timer on any user interaction
    const resetTimer = () => setLastActivity(Date.now());

    // List of events to monitor for user activity
    const events = ['mousemove', 'mousedown', 'keydown', 'scroll', 'touchstart'];

    // Attach event listeners for activity
    events.forEach(event => {
      window.addEventListener(event, resetTimer);
    });

    // Check if the user has been inactive for longer than the timeout
    const checkInactivity = () => {
      if (Date.now() - lastActivity > timeout) {
        showError('Logout due to inactivity');
        delay(() => {
          onLogout();
          navigate(SIGN_IN);
        }, NAVIGATION_DELAY);
        hide();
      }
    };

    // Set an interval to check inactivity
    const intervalId = setInterval(checkInactivity, 1000);

    return () => {
      // Cleanup on unmount
      events.forEach(event => {
        window.removeEventListener(event, resetTimer);
      });
      clearInterval(intervalId);
    };
  }, [lastActivity, timeout, showError, hide, skip, navigate, onLogout]);
};

export default useInactivityLogout;
